<script>

//import  {SimpleBar}  from "simplebar-vue";
import { authMethods } from "@/state/helpers"

export default {
  components: {
   // SimpleBar
  },
  data() {
    return {
      darkMode: false,
      settings: {
        minScrollbarLength: 60,
      },
    };
  },
  computed: {
  },

  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },

    darkMode(){
      if (this.darkMode){
        let el = document.getElementById("html")
        el.setAttribute("data-layout-mode", "dark");
        localStorage.setItem("layoutMode","dark")
      }
      else{
        let el = document.getElementById("html")
        el.setAttribute("data-layout-mode", "light");
        localStorage.setItem("layoutMode","light")
}
    }
  },

  mounted() {

    try{
      var darkMode = localStorage.getItem("layoutMode")
      if (darkMode==="light"){
        this.darkMode = false
      }
      if (darkMode==="dark"){
        this.darkMode = true
      }
    }catch{
      localStorage.setItem("layoutMode", "light")
      this.darkMode = false
    }

    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse");

      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation();
          let closestCollapse = collapse.parentElement.closest(".collapse");
          if (closestCollapse) {
            let siblingCollapses =
              closestCollapse.querySelectorAll(".collapse");
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                siblingCollapse.classList.remove("show");
              }
            });
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = [];
              let sibling = elem.parentNode.firstChild;
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling);
                }
                sibling = sibling.nextSibling;
              }
              return siblings;
            };
            let siblings = getSiblings(collapse.parentElement);
            siblings.forEach((item) => {
              if (item.childNodes.length > 2)
                item.firstElementChild.setAttribute("aria-expanded", "false");
              let ids = item.querySelectorAll("*[id]");
              ids.forEach((item1) => {
                item1.classList.remove("show");
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a");

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false");
                  });
                }
              });
            });
          }
        });

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation();
          let childCollapses = collapse.querySelectorAll(".collapse");
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse;
            childCollapseInstance.style.display = "none";
          });
        });
      });
    }
  },

  methods: {
    ...authMethods,
    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if (this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
      }
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]');
          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add("active");
              parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
              if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                  parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active");
                const grandparent = parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.parentElement.closest(".collapse");
                if (grandparent && grandparent && grandparent.previousElementSibling) {
                  grandparent.previousElementSibling.classList.add("active");
                  grandparent.classList.add("show");
                }
              }
            }
          }
        }
      }, 0);
    },
  },

};
</script>

<template>
  <b-container fluid>

      <div class="navbar-nav" id="navbar-nav">
        <ul class="navbar-nav h-100" id="navbar-nav">
          <!-------------------------------- ### SignalStation ### -------------------------------------->
          <!--li class="nav-item">
            <router-link to="/signal-station/signals" class="nav-link menu-link">
              <i class="ri-signal-tower-line fs-22"></i>
              <span data-key="signalstation"> Signal Station </span>
            </router-link>
          </li-->

          <!-------------------------------- ### Dashboard ### -------------------------------------->
          <li class="nav-item">
            <router-link to="/dashboard/overview" class="nav-link menu-link">
              <i class="ri-dashboard-fill fs-22"></i>
              <span data-key="dashboard"> Dashboard </span>
            </router-link>
          </li>

          <!-------------------------------- ### Product Hunt ### -------------------------------------->
          <li class="nav-item">
            <router-link to="/product-hunt/tasks" class="nav-link menu-link">
              <i class="ri-product-hunt-line fs-22"></i>
              <span data-key="ProductHunt"> Product Hunt </span>
            </router-link>
          </li>

          <!-------------------------------- ### Product Hunt AU### -------------------------------------->
          <li class="nav-item">
            <router-link to="/product-hunt-au/tasks" class="nav-link menu-link">
              <i class="ri-map-pin-line fs-22"></i>
              <span data-key="ProductHunt"> Product Hunt AU </span>
            </router-link>
          </li>

          <!-------------------------------- ### Product Hunt Light### -------------------------------------->
          <li class="nav-item">
            <router-link to="/product-hunt-light/tasks-light" class="nav-link menu-link">
              <i class="ri-product-hunt-line fs-22"></i>
              <span data-key="ProductHuntLight"> Product Hunt<br>Light </span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link to="/boxmonitor/offers" class="nav-link menu-link">
              <i class="ri-24-hours-line fs-22"></i>
              <span data-key="boxmonitor"> BoxMonitor </span>
            </router-link>
          </li>

          
          <li class="nav-item">
            <router-link to="/reports/inventory" class="nav-link menu-link">
              <i class="ri-bubble-chart-fill fs-22"></i>
              <span data-key="reports"> Reports </span>
            </router-link>
          </li>
                    
          <li class="nav-item">
            <router-link to="/seller-spy/sellers" class="nav-link menu-link">
              <i class="ri-spy-line fs-22"></i>
              <span data-key="sellerspy"> Seller Spy </span>
            </router-link>
          </li>

          

          <!-------------------------------- ### BUSINESS ### -------------------------------------->
          <li class="nav-item">
            <router-link to="/business/inboxes" class="nav-link menu-link">
              <i class="ri-hand-coin-line fs-22"></i>
              <span data-key="Business"> Business </span>
            </router-link>
          </li>

        </ul>
      </div>
      
  </b-container>
</template>